import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainPart, Figure, List, MainTitle,
} from '../../components/opComponents';

const CompressionDuNerfUlnaireAuCoude = () => (
  <Layout
    title="Compression du nerf ulnaire au coude : Diagnostic et traitement - Dr Ghazanfari"
    description="Dans le cas d'une compression du nerf ulnaire au coude, le patient se plaint de fourmillements, de brulures au niveau des 4 et 5èmes doigts."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-du-coude" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations du coude</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Compression du nerf ulnaire au coude" />
        <MainPart title="Qu'est-ce qu'une compression du nerf ulnaire du coude ?">
          <Typography>
            Le site de compression le plus fréquent
            du nerf ulnaire est situé au niveau du coude.
          </Typography>
        </MainPart>
        <MainPart title="Qu'est-ce que le nerf ulnaire ?">
          <Typography>
            Le nerf ulnaire est un nerf mixte : il est responsable
            de la sensibilité (moitié interne de la paume, 5ème doigt,
            moitié interne du 4ème doigt) et de la motricité
            (muscles interosseux) de la main (Figure 1).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-compressiondunerfulnaireaucoude.jpg"
            caption="Figure 1. Territoire cutané de la main innervé par le nerf ulnaire"
            currentOpTitle="Compression du nerf ulnaire au coude"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes d'une compression du nerf ulnaire ?">
          <Typography weight="bold">
            Le nerf ulnaire traverse un canal ostéo-fibreux au niveau du coude.
            {' '}
            <Typography variant="span">
              Ce canal peut être naturellement étroit ou
              secondairement rétrécie (kyste, fracture ancienne du coude etc).
              L&apos;appui continu et prolongé sur le coude (travail de bureau
              ou en dormant la nuit) participe à l&apos;aggravation des
              symptômes.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes d'une compression du nerf ulnaire ?">
          <Typography>
            Le diagnostic est clinique.
            {' '}
            <Typography variant="span" weight="bold">Le patient se plaint de fourmillements, de brulures voire d&apos;électricité au niveau des 4 et 5èmes doigts.</Typography>
            {' '}
            Ces symptômes sont le plus souvent présents la nuit pouvant être responsables
            de réveils nocturnes. On objective le plus souvent des décharges électriques
            au niveau des deux derniers doigts lors de la percussion du nerf au niveau
            de sa gouttière située à la face interne du coude.
          </Typography>
          <Typography>
            Dans les formes évoluées, on peut observer une maladresse de la main, une perte
            de la sensibilité des doigts voire une fonte musculaire de la première
            commissure (entre le pouce et l&apos;index) et/ou une attitude en
            griffe des 4 et 5èmes doigts (Figure 2).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-compressiondunerfulnaireaucoude.jpg"
            caption="Figure 2. Forme sévère de compression du nerf ulnaire au coude responsable d&apos;une griffe des 4 et 5èmes rayons de la main"
            currentOpTitle="Compression du nerf ulnaire au coude"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer une compression du nerf ulnaire ?">
          <Typography>
            L&apos;électromyogramme (EMG) est réalisé par un neurologue en cabinet,
            à l&apos;aide de petites aiguilles, permettant d&apos;enregistrer le courant
            électrique véhiculé par le nerf. Cet examen, réalisé au repos,
            peut parfois sous-estimer la compression du nerf ulnaire qui est
            le plus souvent dynamique (lors de la flexion du coude).
          </Typography>
          <Typography>
            L&apos;
            <a className="underline text-link hover:text-primary" href="https://www.passeportsante.net/fr/Maux/examens-medicaux-operations/Fiche.aspx?doc=electromyogramme#:~:text=Examen%20de%20r%C3%A9f%C3%A9rence%20en%20neurologie,diff%C3%A9rentes%20pathologies%20nerveuses%20et%https://www.passeportsante.net/fr/Maux/examens-medicaux-operations/Fiche.aspx?doc=electromyogramme#:~:text=Examen%20de%20r%C3%A9f%C3%A9rence%20en%20neurologie,diff%C3%A9rentes%20pathologies%20nerveuses%20et%20musculaires.20musculaires." target="_blank" rel="noreferrer">EMG</a>
            {' '}
            permet de :
          </Typography>
          <List>
            <List.Item>
              <Typography>confirmer le siège de la compression nerveuse</Typography>
            </List.Item>
            <List.Item>
              <Typography>préciser l&apos;importance de la compression nerveuse</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                rechercher une compression nerveuse associée
                (compression du nerf médian au canal carpien le plus souvent)
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Les radiographies du coude peuvent mettre en évidence une
            éventuelle anomalie osseuse responsable de la compression.
            L&apos;échographie et l&apos;IRM ne sont pas indispensables en première intention.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont le traitement médical et le traitement chirurgical pour une compression du nerf ulnaire au coude ?">
          <Typography size="mdx" weight="bold">
            Médical :
          </Typography>
          <Typography>
            Dans les formes débutantes, le changement de la gestuelle pouvant être responsable
            de la compression ainsi que le port d&apos;une attelle nocturne en extension du coude
            peuvent être envisagés. Les infiltrations de corticoïdes sont dangereuses au
            niveau du coude en raison du risque important de lésion du nerf ulnaire.
          </Typography>
          <Typography size="mdx" weight="bold">
            Chirurgical :
          </Typography>
          <Typography>
            Le traitement chirurgical est indiqué en cas d&apos;échec du traitement
            médical ou d&apos;emblée dans les formes sévères et/ou déficitaires.
            L&apos;intervention est le plus souvent réalisée sous anesthésie loco-régionale
            (le bras est uniquement endormi), en ambulatoire (pas d&apos;hospitalisation).
          </Typography>
          <Typography>
            Le principe de l&apos;intervention est de décomprimer le nerf ulnaire. L&apos;incision
            est située sur le bord interne du coude. Après libération, en cas
            d&apos;instabilité du nerf, celui-ci passe sur l&apos;épitrochlée
            (saillie interne du coude)
            lors de la flexion du coude ce qui peut provoquer à terme une irritation
            du nerf ulnaire (Figure 3).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure3-compressiondunerfulnaireaucoude.jpg"
            caption="Figure 3. Libération du nerf ulnaire au coude. Notez l&apos;instabilité du nerf (sorti de sa gouttière), induit par sa libération"
            currentOpTitle="Compression du nerf ulnaire au coude"
          />
          <Typography>
            Dans cette situation, le nerf est transposé en avant de l&apos;épitrochlée
            et fixé en place à l&apos;aide d&apos;un lambeau le plus souvent graisseux
            (Figures 4 et 5).
          </Typography>
          <div className="flex flex-col space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure4-compressiondunerfulnaireaucoude.jpg"
              caption="Figure 4. Prélèvement d&apos;un lambeau graisseux afin de stabiliser le nerf ulnaire"
              currentOpTitle="Compression du nerf ulnaire au coude"
            />
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure5-compressiondunerfulnaireaucoude.jpg"
              caption="Figure 5. Stabilisation du nerf ulnaire à l&apos;&apos;aide du lambeau graisseux"
              currentOpTitle="Compression du nerf ulnaire au coude"
            />
          </div>
        </MainPart>
        <MainPart title="Quelles sont les suites posts-opératoires après une opération chirurgicale pour une compression du nerf ulnaire ?">
          <Typography>
            Les engourdissements disparaissent en
            général le jour de l&apos;intervention.
          </Typography>
          <Typography>
            Le coude est immobilisé dans une écharpe
            pendant une dizaine de jours.
          </Typography>
          <Typography>
            Un petit pansement est réalisé tous les
            2-3 jours pendant 14 jours.
          </Typography>
          <Typography>
            La rééducation post-opératoire peut être nécessaire 3 semaines
            après l&apos;opération afin de récupérer les amplitudes du coude.
          </Typography>
          <Typography>
            En cas de perte de sensibilité et /ou de motricité avant l&apos;opération,
            la récupération peut être longue voire incomplète dans les formes sévères.
          </Typography>
          <Typography>
            La durée de l&apos;arrêt de travail est variable pouvant aller
            de 3 à 6 semaines en fonction de la profession du patient.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une compression du nerf ulnaire ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Lésion nerveuse, nécessitant une suture micro-chirurgicale du nerf
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Récupération incomplète en cas de formes sévères et/ou déficitaires (Figure 6)
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure6-compressiondunerfulnaireaucoude.jpg"
            caption="Figure 6. Forme sévère de compression du nerf ulnaire au coude avec un aspect de nerf en « sablier »"
            currentOpTitle="Compression du nerf ulnaire au coude"
          />
          <List>
            <List.Item>
              <Typography>
                La récidive est inhabituelle mais n&apos;est jamais exclue
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="coude" />
  </Layout>
);

export default CompressionDuNerfUlnaireAuCoude;
